import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import EmployeeJobTypeCreateView from '../sections/Configuration/EmployeeJobTypeCreateView'

const EmployeeJobTypeCreate = () => {
  return (
    <>
      <Helmet>
        <title>Agregar Trabajo | Sistema Planilla</title>
      </Helmet>
      
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <EmployeeJobTypeCreateView />
      </MsalAuthenticationTemplate>
    </>
  )
}

export default EmployeeJobTypeCreate
