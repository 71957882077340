import ForestIcon from '@mui/icons-material/Forest'
import { Avatar, Box } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import DialogContentText from '@mui/material/DialogContentText'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid2'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { AxiosError } from 'axios'
import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiError, axiosPrivate } from '../../api/axios'
import { endpoints } from '../../api/constants'
import DynamicDialog from '../../components/DynamicDialog'
import Iconify from '../../components/iconify'
import Loading from '../../components/Loading/Loading'
import { useAuth } from '../../context/AuthProvider'
import { useSnackbar } from '../../context/SnackbarProvider'
import { FarmDetails } from "../../types/Farm"
import { formatDateTimeCustom } from '../../utils/format-time'

type SetFarmActiveStatusRequest = {
  id: number  
  updatedByUserEmail: string
  active: boolean
}


const FarmDetailsView: FC = () => {
   const { id } = useParams<{ id: string }>()
   const navigate = useNavigate()
   const { auth } = useAuth()
   const { showError, showSuccess } = useSnackbar()
   const [isLoading, setIsLoading] = useState<boolean>(false)
   const [farm, setFarm] = useState<FarmDetails | null>(null)
   const [openChangeStatusModal, setOpenChangeStatusModal] = useState(false)

   const getFarmDetails = async (controller: AbortController) => {
       try {
          const response = await axiosPrivate.get(
            `${endpoints.getFarm}${id}`, {
            signal: controller.signal,
          })
         setIsLoading(false)
         setFarm(response.data)
       } catch (err: any) {
         setIsLoading(false)
         const error = err as AxiosError
         if (error.name !== 'CanceledError') {
           showError('Error al obtener los detalles de la finca')
         }
       }
     }
     
     useEffect(() => {
       let isMounted = true
       const controller = new AbortController()
       setIsLoading(true)    
     
       if (isMounted) {
         getFarmDetails(controller)
       }
     
       return () => {
         isMounted = false
         controller.abort()
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [])     
     
   
     const SetFarmActiveStatus = async (request: SetFarmActiveStatusRequest) => {
         setIsLoading(true)
         const controller = new AbortController()
     
         const SendSetFarmActiveStatusRequest = async () => {    
             try {
               const response = await axiosPrivate.put(
                 endpoints.setFarmActiveStatus,
                 request,
                 { signal: controller.signal }
               )         
         
               if (response.status === 200) {
                 showSuccess('La finca ha sido actualizada correctamente!')         
                 getFarmDetails(controller)      
               }
             } catch (err: any) {
               const error = err as AxiosError<ApiError>
         
               if (error.name !== 'CanceledError') {
                 showError('Error actualizar la finca ' + error?.response?.data?.detail)
               }
             } finally {
               setIsLoading(false)
             }
           }
     
           SendSetFarmActiveStatusRequest()
       }
   
     const handleSetFarmActiveStatus = () => {
       if (farm) {
         const request: SetFarmActiveStatusRequest = {
           id: farm.id,
           active: !farm.isDeleted,
           updatedByUserEmail: auth.user?.email || '', //TODO: Validate user,
         }
         SetFarmActiveStatus(request)        
       }
     }
   
     const handleEditFarm = () => {
       if (farm) {
         navigate(`/fincas/editar/${id}`, { state: { id: id } })
       }
     }
   
     const handleOnCloseChangeStatusModal = () => {
       setOpenChangeStatusModal(false)
     }
   
     const dialogAcceptButton = {
       text: 'Confirmar',
       action: () => {
         handleSetFarmActiveStatus()
         setOpenChangeStatusModal(false)
       },
     }
   
     const dialogCancelButton = {
       text: 'Cancelar',
       action: () => {      
         setOpenChangeStatusModal(false)
       },
     }     

     return (
       <Container>
         <Stack
           direction='row'
           alignItems='center'
           justifyContent='space-between'
           mb={5}
         >
           <Grid container sx={{ width: '100%' }}>
             <Grid
               size={12}
               sx={{
                 mb: 2,
                 display: 'flex',
                 justifyContent: 'flex-start',
               }}
             >
               <Button
                 onClick={() => {
                   navigate('/fincas')
                 }}
                 variant='contained'
                 color='inherit'
                 startIcon={<Iconify icon='eva:arrow-back-fill' />}
               >
                 Regresar
               </Button>
             </Grid>

             <Grid size={{ xs: 12, md: 8 }}>
               <Typography variant='h4'>Detalles de la finca</Typography>
             </Grid>
           </Grid>
         </Stack>

         <Grid container sx={{ width: '100%' }}>
           {isLoading && <Loading centered={true} />}
           <Grid
             size={{ xs: 12, md: 3.8 }}
             sx={{ mr: { md: 2 }, mb: { xs: 3 } }}
           >
             <Card>
               <CardContent>
                 <Box
                   display='flex'
                   flexDirection='column'
                   alignItems='center'
                   sx={{ mt: 2 }}
                 >
                   <Avatar
                     src={
                      farm?.imageUrl
                         ? `${farm?.imageUrl}?${new Date().getTime()}`
                         : undefined
                     }
                     alt='Farm Avatar'
                     sx={{ width: 180, height: 180, mb: 2 }}
                   >
                     {!farm?.imageUrl && (
                       <ForestIcon sx={{ fontSize: 80 }} />
                     )}
                   </Avatar>                   
                 </Box>
                 <Box
                   display='flex'
                   justifyContent='center'
                   width='100%'
                   sx={{ mt: 2 }}
                 >
                   <Typography
                     component='span'
                     sx={{
                       color: farm?.isDeleted ? '#B71D18' : '#118D57',
                       backgroundColor: farm?.isDeleted
                         ? 'rgba(255, 86, 48, 0.16)'
                         : 'rgba(34, 197, 94, 0.16)',
                       padding: '5px',
                       borderRadius: '3px',
                     }}
                   >
                     {farm?.isDeleted ? 'Desactivado' : 'Activo'}
                   </Typography>
                 </Box>
               </CardContent>
             </Card>
           </Grid>
           <Grid size={{ xs: 12, md: 8 }}>
             <Card>
               <CardContent>
                 <Grid container spacing={2}>
                   {isLoading && <Loading centered={true} />}
                   <Grid size={{ xs: 12 }}>
                     <Typography variant='body1'>
                       <strong>Nombre:</strong> {farm?.name}
                     </Typography>
                   </Grid>
                   <Grid size={{ xs: 12 }}>
                     <Typography variant='body1'>
                       <strong>Perfil:</strong> {farm ? farm.tenant.name : ''}
                     </Typography>
                   </Grid>
                   <Grid size={{ xs: 12 }}>
                     <Typography variant='body1'>
                       <strong>Tamaño (hectáreas):</strong> {farm?.propertySize}
                     </Typography>
                   </Grid>
                   <Grid size={{ xs: 12 }}>
                     <Typography variant='body1'>
                       <strong>Dirección:</strong> {farm?.address}
                     </Typography>
                   </Grid>
                   <Grid size={{ xs: 12 }}>
                     <Typography variant='body1'>
                       <strong>Fecha creación:</strong>{' '}
                       {formatDateTimeCustom(farm?.createdDate)} (
                       {farm?.createdByUserEmail})
                     </Typography>
                   </Grid>
                   <Grid size={{ xs: 12 }}>
                     <Typography variant='body1'>
                       <strong>Última modificación:</strong>{' '}
                       {formatDateTimeCustom(farm?.modifiedDate)} (
                       {farm?.modifiedByUserEmail})
                     </Typography>
                   </Grid>
                 </Grid>
               </CardContent>
               <Divider />
               <CardActions
                 sx={{ justifyContent: 'flex-end', mt: 2, mr: 1, mb: 1 }}
               >
                 <Button
                   onClick={handleEditFarm}
                   variant='contained'
                   color='inherit'
                 >
                   Editar
                 </Button>
                 {farm?.isDeleted ? (
                   <Button
                     onClick={() => {
                       setOpenChangeStatusModal(true)
                     }}
                     variant='contained'
                     color='success'
                   >
                     Activar
                   </Button>
                 ) : (
                   <Button
                     onClick={() => {
                       setOpenChangeStatusModal(true)
                     }}
                     variant='contained'
                     color='error'
                   >
                     Desactivar
                   </Button>
                 )}
               </CardActions>

               <DynamicDialog
                 openDialog={openChangeStatusModal}
                 dialogTitle={`Confirmar ${
                   farm?.isDeleted ? 'activación' : 'desactivación'
                 }`}
                 onCloseAction={handleOnCloseChangeStatusModal}
                 buttonAccept={dialogAcceptButton}
                 buttonCancel={dialogCancelButton}
               >
                 <DialogContentText id='generic-dialog-description'>
                   {farm?.isDeleted ? '¿Activar finca?' : '¿Desactivar finca?'}
                 </DialogContentText>
               </DynamicDialog>
             </Card>
           </Grid>
         </Grid>
       </Container>
     )
  }

export default FarmDetailsView