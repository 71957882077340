import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import FarmJobTypeView from '../sections/Configuration/FarmJobTypeView'

const FarmJobType = () => {
  return (
    <>
      <Helmet>
        <title>Trabajos Fincas | Sistema Planilla</title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <FarmJobTypeView />
      </MsalAuthenticationTemplate>      
    </>
  )
}

export default FarmJobType
