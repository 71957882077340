import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { createContext, FC, ReactNode, useContext, useState } from "react"
import { Employee } from "../types/Employee"
import { Farm } from "../types/Farm"
import { FarmJobType, JournalDailyWork, JournalDailyWorkDetail } from "../types/Journal"
import { JournalDailyWorkProduct, ProductInventory } from "../types/Product"

dayjs.extend(utc)
interface DailyWorkReportContextProps {
    selectedFarm: Farm | null
    setSelectedFarm: (farm: Farm | null) => void
    selectedFarmJobTypes: FarmJobType[]
    setSelectedFarmJobTypes: (farmJobTypes: FarmJobType[]) => void
    selectedDate: Dayjs | null
    setSelectedDate: (date: Dayjs | null) => void
    observations: string | undefined
    setObservations: (observations: string | undefined) => void
    selectedProducts: ProductInventory[]
    setSelectedProducts: (products: ProductInventory[]) => void
    journalDailyWorkProducts: JournalDailyWorkProduct[]
    setJournalDailyWorkProducts: (journalDailyWorkProducts: JournalDailyWorkProduct[]) => void
    selectedEmployees: Employee[]
    setSelectedEmployees: (employees: Employee[]) => void
    journalDailyWorkDetails: JournalDailyWorkDetail[]
    setJournalDailyWorkDetails: (journalDailyWorkDetails: JournalDailyWorkDetail[]) => void
    isEditing: boolean
    setIsEditing: (isEditing: boolean) => void
}

const DailyWorkReportContext = createContext<DailyWorkReportContextProps>({
    selectedFarm: null,
    setSelectedFarm: () => {},
    selectedFarmJobTypes: [],
    setSelectedFarmJobTypes: () => {},
    selectedDate: null,
    setSelectedDate: () => {},
    observations: undefined,
    setObservations: () => {},
    selectedProducts: [],
    setSelectedProducts: () => {},
    journalDailyWorkProducts: [],
    setJournalDailyWorkProducts: () => {},
    selectedEmployees: [],
    setSelectedEmployees: () => {},
    journalDailyWorkDetails: [],
    setJournalDailyWorkDetails: () => {},
    isEditing: false,
    setIsEditing: () => {},
})

DailyWorkReportContext.displayName = "DailyWorkReportContext"

const initializeState = (initialData?: JournalDailyWork) => {    
    return {
        farm: initialData?.farm ? { ...initialData.farm, tenantId: initialData.tenantId } : null,
        farmJobTypes: initialData?.journalDailyWorkFarmJobTypes.map(detail => detail.farmJobType) || [],
        date: initialData ?  dayjs.utc(initialData.journalDailyWorkDate) : null,
        observations: initialData?.description || undefined,
        products: initialData?.journalDailyWorkProducts.map(detail => detail.productInventory) || [],
        journalProductDetails: initialData?.journalDailyWorkProducts.map(detail => {
            return {
                id: detail.id,
                productInventory: detail.productInventory, 
                productQuantity: detail.productQuantity,
                productPrice: detail.productPrice
            }
        }) || [],
        employees: initialData?.journalDailyWorkDetails.map(detail => detail.employee) || [],
        journalWorkDetails: initialData?.journalDailyWorkDetails.map(detail => {
            return {
                id: detail.id,
                employee: detail.employee,
                employeeJobType: detail.employeeJobType,
                jobQuantity: detail.jobQuantity,
                jobPrice: detail.jobPrice,
                paid: detail.paid
            }
        }) || [],
    }
}

interface DailyWorkReportProviderProps {
    children: ReactNode
    initialData?: JournalDailyWork // Optional initial data
}  

const DailyWorkReportProvider: FC<DailyWorkReportProviderProps> = ({ children, initialData }) => {
    const initialState = initializeState(initialData)
   
    const [selectedFarm, setSelectedFarm] = useState<Farm | null>(initialState.farm)
    const [selectedFarmJobTypes, setSelectedFarmJobTypes] = useState<FarmJobType[]>(initialState.farmJobTypes)
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(initialState.date)
    const [observations, setObservations] = useState<string | undefined>(initialState.observations)
    const [selectedProducts, setSelectedProducts] = useState<ProductInventory[]>(initialState.products)
    const [journalDailyWorkProducts, setJournalDailyWorkProducts] = useState<JournalDailyWorkProduct[]>(initialState.journalProductDetails)
    const [selectedEmployees, setSelectedEmployees] = useState<Employee[]>(initialState.employees)
    const [journalDailyWorkDetails, setJournalDailyWorkDetails] = useState<JournalDailyWorkDetail[]>(initialState.journalWorkDetails)
    const [isEditing, setIsEditing] = useState<boolean>(false)
    
    return (
        <DailyWorkReportContext.Provider value={{
            selectedFarm,
            setSelectedFarm,
            selectedFarmJobTypes,
            setSelectedFarmJobTypes,
            selectedDate,
            setSelectedDate,
            observations,
            setObservations,
            selectedProducts,
            setSelectedProducts,
            journalDailyWorkProducts,
            setJournalDailyWorkProducts,
            selectedEmployees,
            setSelectedEmployees,
            journalDailyWorkDetails,
            setJournalDailyWorkDetails,
            isEditing,
            setIsEditing,
        }}>
            {children}
        </DailyWorkReportContext.Provider>
    )
}

const useDailyWorkReport = (): DailyWorkReportContextProps => {
    const context = useContext(DailyWorkReportContext)
    if (!context) {
        throw new Error("useDailyWorkReport must be used within an DailyWorkReportProvider")
    }

    return context
}

export { DailyWorkReportProvider, useDailyWorkReport }

