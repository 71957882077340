import { Tenant } from "../context/AuthProvider"

export type Farm = {
    id: number
    imageUrl: string
    name: string    
    tenantId: number
  }

  export type FarmDetails = {
    id: number
    imageUrl: string
    name: string
    lastJournalReportDate: Date 
    tenant: Tenant
    isDeleted: boolean
    address: string
    propertySize: number
    createdDate: string
    modifiedDate: string
    createdByUserEmail: string
    modifiedByUserEmail: string
  }

  
export enum FarmStatus {
  Active = 1,
  Inactive = 2,
  All = 3
}

export function getFarmStatusLabel(
  status: FarmStatus
): string {
  switch (status) {
    case FarmStatus.Active:
      return 'Activo'
    case FarmStatus.Inactive:
      return 'Desactivado'
    case FarmStatus.All:
      return 'Todo'
    default:
      return 'Desconocido'
  }
}