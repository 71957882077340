import { Route, Routes } from 'react-router-dom'
import PersistLogin from '../components/Auth/PersistLogin'
import RequireAuth from '../components/Auth/RequireAuth'
import Unauthorized from '../components/Auth/Unauthorized'
import LogOut from '../components/LogOut/LogOut'
import Missing from '../components/Missing/Missing'
import Layout from '../layouts/Layout'
import CalculatePayroll from '../pages/CalculatePayroll'
import CreateDailyWorkReport from '../pages/CreateDailyWorkReport'
import CreateProductInventory from '../pages/CreateProductInventory'
import DailyWorkReport from '../pages/DailyWorkReport'
import DailyWorkReportDetails from '../pages/DailyWorkReportDetails'
import EditDailyWorkReport from '../pages/EditDailyWorkReport'
import EditProductInventory from '../pages/EditProductInventory'
import Employee from '../pages/Employee'
import EmployeeCreate from '../pages/EmployeeCreate'
import EmployeeDetails from '../pages/EmployeeDetails'
import EmployeeEdit from '../pages/EmployeeEdit'
import EmployeeJobType from '../pages/EmployeeJobType'
import EmployeeJobTypeCreate from '../pages/EmployeeJobTypeCreate'
import EmployeeJobTypeDetails from '../pages/EmployeeJobTypeDetails'
import EmployeeJobTypeEdit from '../pages/EmployeeJobTypeEdit'
import Farm from '../pages/Farm'
import FarmCreate from '../pages/FarmCreate'
import FarmDetails from '../pages/FarmDetails'
import FarmEdit from '../pages/FarmEdit'
import FarmJobType from '../pages/FarmJobType'
import FarmJobTypeCreate from '../pages/FarmJobTypeCreate'
import FarmJobTypeEdit from '../pages/FarmJobTypeEdit'
import Home from '../pages/Home'
import LoginPage from '../pages/Login'
import Payroll from '../pages/Payroll'
import PayrollDetails from '../pages/PayrollDetails'
import ProductInventory from '../pages/ProductInventory'

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* public routes */}
        <Route path='login' element={<LoginPage />} />
        <Route path='unauthorized' element={<Unauthorized />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='/' element={<Home />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='reporte-trabajo' element={<DailyWorkReport />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='reporte-trabajo/detalles/:journalDailyWorkId' element={<DailyWorkReportDetails />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='reporte-trabajo/agregar'
              element={<CreateDailyWorkReport />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='reporte-trabajo/editar/:reportId'
              element={<EditDailyWorkReport />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='planilla/calcular-pagar' element={<CalculatePayroll />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='planilla/detalles/:payrollMasterId' element={<PayrollDetails />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='planilla' element={<Payroll />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='inventario-productos' element={<ProductInventory />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='inventario-productos/agregar'
              element={<CreateProductInventory />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='inventario-productos/editar/:productId'
              element={<EditProductInventory />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='empleados' element={<Employee />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='empleados/detalles/:id'
              element={<EmployeeDetails />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='empleados/editar/:id'
              element={<EmployeeEdit />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='empleados/agregar'
              element={<EmployeeCreate />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='trabajos-fincas' element={<FarmJobType />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='trabajos-fincas/editar/:id'
              element={<FarmJobTypeEdit />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='trabajos-fincas/agregar'
              element={<FarmJobTypeCreate />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='trabajos-empleados' element={<EmployeeJobType />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='trabajos-empleados/editar/:id'
              element={<EmployeeJobTypeEdit />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='trabajos-empleados/detalles/:id'
              element={<EmployeeJobTypeDetails />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='trabajos-empleados/agregar'
              element={<EmployeeJobTypeCreate />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route path='fincas' element={<Farm />} />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='fincas/detalles/:id'
              element={<FarmDetails />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='fincas/editar/:id'
              element={<FarmEdit />}
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={[]} />}>
            <Route
              path='fincas/agregar'
              element={<FarmCreate />}
            />
          </Route>

          <Route path='/logout' element={<LogOut />} />
        </Route>

        {/* catch all */}
        <Route path='*' element={<Missing />} />
      </Route>
    </Routes>
  )
}

export default Router