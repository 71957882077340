import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import EmployeeJobTypeDetailsView from '../sections/Configuration/EmployeeJobTypeDetailsView'

const EmployeeJobTypeDetails = () => {    
    return (
      <>
        <Helmet>
          <title>Empleado Editar | Sistema Planilla</title>
        </Helmet>
  
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
          <EmployeeJobTypeDetailsView  />
        </MsalAuthenticationTemplate>
      </>
    )

}

export default EmployeeJobTypeDetails