import { InteractionType } from '@azure/msal-browser'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { Helmet } from 'react-helmet-async'
import EmployeeJobTypeView from '../sections/Configuration/EmployeeJobTypeView'

const EmployeeJobType = () => {
  return (
    <>
      <Helmet>
        <title>Trabajos Empleados | Sistema Planilla</title>
      </Helmet>

      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <EmployeeJobTypeView />
      </MsalAuthenticationTemplate>      
    </>
  )
}

export default EmployeeJobType
